<template>
  <div style="height: 100vh" class="d-flex flex-column">
    <div class="container flex-grow-1">
      <div class="wrapper h-100 d-flex flex-column">
        <Header title="新增付款資料" backto="/payment" />
        <h6 style="color: #ff0000">必填欄位*</h6>
        <div class="row my-3">
          <div class="col">
            <label for="number">信用卡卡號<span class="required">*</span></label>
            <div class="form-control" ref="number"></div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label for="cardExpirationDate">到期日<span class="required">*</span></label>
            <div class="form-control" ref="expirationDate"></div>
          </div>
          <div class="col">
            <label for="cardCcv">安全碼<span class="required">*</span></label>
            <div class="form-control" ref="ccv"></div>
          </div>
        </div>
        <!-- <div v-show="false" class="row">
          <div class="col-6">
            <h6>優惠代碼</h6>
            <b-form-input v-model="coupon" type="number" />
          </div>
        </div> -->
      </div>
    </div>
    <div @click="onSubmit">
      <Button name="新增"></Button>
    </div>
  </div>
</template>

<script>
import { payment } from '@/mixins';
import Header from '@/components/Header.vue';
import Button from '@/elements/Button.vue';

export default {
  mixins: [payment],
  name: 'AddPayment',
  components: { Header, Button },
  data() {
    return {
      step: 0,
      states: [],
    };
  },
  mounted() {
    TPDirect.setupSDK(
      '13886',
      'app_RcmJHxrLU1rnPfLLaKEx8zxDBesXZlcfM9aa5Uf4q5BCEwJOwcVVd9c0DDXC',
      process.env.VUE_APP_TAPPAY_ENV
    );

    const fields = {
      number: {
        element: this.$refs.number,
        placeholder: '**** **** **** ****',
      },
      expirationDate: {
        element: this.$refs.expirationDate,
        placeholder: 'MM/YY',
      },
      ccv: {
        element: this.$refs.ccv,
        placeholder: '安全碼',
      },
    };

    TPDirect.card.setup({
      fields,
      styles: {
        // Style all elements
        input: {
          color: 'gray',
        },
        // Styling ccv field
        'input.cvc': {
          'font-size': '16px',
        },
        // Styling expiration-date field
        'input.expiration-date': {
          // 'font-size': '16px'
        },
        // Styling card-number field
        'input.card-number': {
          // 'font-size': '16px'
        },
        // style focus state
        ':focus': {
          // 'color': 'black'
        },
        // style valid state
        '.valid': {
          color: 'green',
        },
        // style invalid state
        '.invalid': {
          color: 'red',
        },
        // Media queries
        // Note that these apply to the iframe, not the root window.
        '@media screen and (max-width: 400px)': {
          input: {
            color: 'orange',
          },
        },
      },
    });

    TPDirect.card.onUpdate((update) => {
      if (update.canGetPrime) {
        // 全部欄位皆為正確 可以呼叫 getPrime
        this.disabledBtnPay = false;
      } else {
        this.disabledBtnPay = false;
      }

      this.updateStatus(update.status.number);
      this.updateStatus(update.status.expiry);
      this.updateStatus(update.status.number);
    });
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    updateStatus(field) {
      switch (field) {
        case 0:
          // 欄位已填好，並且沒有問題
          console.log('field is ok');
          break;
        case 1:
          // 欄位還沒有填寫
          console.log('field is empty');
          break;
        case 2:
          // 欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor
          console.log('field has error');
          break;
        case 3:
          // 使用者正在輸入中
          console.log('usertyping');
          break;
        default:
          console.log('error!');
      }
    },
    onSubmit() {
      const tappayStatus = TPDirect.card.getTappayFieldsStatus();
      if (tappayStatus.canGetPrime === false) {
        // can not get prime
        alert('信用卡資訊格式錯誤，請修正後再試一次');
        return;
      }

      // Get prime
      TPDirect.card.getPrime((result) => {
        if (result.status !== 0) {
          // get prime error
          console.log(result.msg);
          return;
        }

        const { prime } = result.card;

        this.submitPrime(prime);
      });
    },
    submitPrime(prime) {
      this.addUserPayment({
        cardholderName: 'cardhloderName',
        prime,
        onSuccess: () => {
          this.$router.back();
        },
        onFailed: (err) => {
          switch (err.response.data.apiErrorCode) {
            case 'DUPLICATED_CARD':
              alert('此信用卡已經加入，無法重複新增相同卡片。');
              break;
            default:
              alert('發生錯誤，請再試一次');
              break;
          }
        },
      });
    },
  },
};
</script>
<style>
.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
}

.required {
  color: #ff0000;
  font-size: 24px;
}
</style>
